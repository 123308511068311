<template>
  <div class="right-content">
    <div
      class="box-label user-center-plus"
      v-if="data.showBtns"
      @click="routeJump({ name: 'newPublish' })"
    >
      <i class="el-icon--plus"></i>
    </div>
    <div class="box-label">
      <div class="content-row contentserver">
        <ul class="services" v-if="data.items && data.items.length">
          <li v-for="(item, index) in data.items" :key="index">
            <div class="hasDeleteServes" v-if="item.is_delete==='3'">
              <div class="leftContent">
                <div class="deleteIconBox">
                  <div class="deleteIcon"></div>
                </div>
                <div>{{$t("violationTips")}}</div>
              </div>
              <div class="rightContent">
                {{item.publish_time}}
              </div>
              <edit
                v-if="data.showBtns"
                :params="{ type: 1, id: item.id }"
                :isDelete="true"
                @delSuccess="delSuccessHandler"
              />
            </div>
            <div class="servicesInner" v-if="item.is_delete==='1'">
              <p class="titleBox">
                <span
                  class="title"
                  @click="routeJump({ name: 'postContentDetails', params: { id: item.id } })"
                  >{{ item.i_can_do }}
                </span>
                <span class="time">{{ item.publish_time }}</span>
              </p>
              <p class="info">
                <img
                  v-if="item.cover"
                  class="thumb"
                  :src="item.cover"
                  @click="routeJump({ name: 'postContentDetails', params: { id: item.id } })"
                />
                <i
                  v-else
                  class="thumb"
                  @click="routeJump({ name: 'postContentDetails', params: { id: item.id } })"
                  >Melinked
                </i>
                <span class="detail">
                  <div
                    v-if="item.isNewDataStructure"
                    class="desc"
                    @click="routeJump({ name: 'postContentDetails', params: { id: item.id } })"
                  >
                    <span v-for="(content,index) in item.detail" :key="content.key + index">
                      {{content.key !== 'web'?content.key === 'url'?content.content.showLink:content.content:content.content.content}}
                    </span>
                  </div>
                  <div
                    v-if="!item.isNewDataStructure"
                    class="desc"
                    v-html="item.detail"
                    @click="routeJump({ name: 'postContentDetails', params: { id: item.id } })"
                  ></div>
                  <label class="row-label">
                    <ul class="tags">
                      <template v-for="(tag, index1) in item.tags.split(',')">
                        <li v-if="tag" :key="index1">
                          {{ tag.length > 50 ? tag.slice(0, 60) + "..." : tag }}
                        </li>
                      </template>
                    </ul>
                    <edit
                      v-if="data.showBtns"
                      :needEdit="true"
                      :isEditPost="true"
                      :params="{ type: 1, id: item.id }"
                      @delSuccess="delSuccessHandler"
                    />
                  </label>
                </span>
              </p>
            </div>
          </li>
        </ul>
        <noData v-else />
      </div>
    </div>
  </div>
</template>
<script>
import noData from "@/components/business/web-site/not-data";
import edit from "@/components/business/web-site/action/edit";
export default {
  name: "Services",
  props: {
    data: {
      type: Object,
      default: () => {
        return {};
      }
    },
    active: {
      type: Number,
      default: 0
    }
  },
  components: {
    noData,
    edit
  },
  watch: {
    // 监听帖子数据
    data: {
      handler(val,old) {
        if(val && val !== old && val.items && val.items.length !== 0) {
          this.handlingCompatibleData(val.items)
        }
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    // 处理新老版本帖子数据兼容问题
    handlingCompatibleData(items) {
      items = items.map( item => {
        if(typeof item.detail === 'string' && item.detail !== '' ) {
          if(item.detail.indexOf('[') === 0 && item.detail.lastIndexOf(']') === item.detail.length-1) {
            item.isNewDataStructure = true;
            let richDetailFromApp = [];
            try {
              let dataFromApp = JSON.parse(item.detail);
              dataFromApp.forEach(element => {
                richDetailFromApp.push(element)
              });
              item.detail = richDetailFromApp;
            } catch (error) {console.log('解析帖子详情失败',item)}
          } else {
            item.isNewDataStructure = false;
          }
        }
        item.cover = this.formartImageUrl(item.cover);
        // 如果是封面是视频，则判断是否取了视频第一帧做封面
        if(this.matchFileSuffixType(item.cover) === 'video' && item.cover.indexOf('?vframe/jpg/offset/0') === -1) {
          item.cover = `${item.cover}?vframe/jpg/offset/0`
        }
        return item;
      })
    },
    // 删除帖子成功
    delSuccessHandler() {
      this.$emit("delSuccess");
    }
  }
};
</script>
<style lang="stylus" scoped>
.user-center-plus {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1.29rem;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }

  i {
    font-size: 36px;
    font-weight: 800;
    color: #33cc66;
  }
}

.contentserver {
  // padding: 0 !important;
  .services {
    width: 100%;
    overflow: hidden;

    li {
      border-bottom: 1px solid #eeeeee;
      padding: 0.9rem;
      max-width: 904px;

      &:last-child {
        border: 0;
      }
      .hasDeleteServes{
        width: 100%;
        display: flex;
        flex-flow: row;
        align-items center;
        position relative;
        justify-content space-between;
        .leftContent{
          height 104px;
          display flex;
          flex-flow row;
          align-items center;
          justify-content left;
          overflow hidden;
          color #666666;
          font-size 12px;
          .deleteIconBox{
            height 65px;
            width 70px;
            display flex;
            margin-right 10px;
            background #E9E9E9;
            align-items center;
            justify-content center;
            .deleteIcon{
              width 33px;
              height 35px;
              background: url("../../../../assets/images/services/deleteIcon.png") no-repeat;
              background-size 100% 100%;
            }
          }
        }
        .rightContent{
          font-size: 12px;
          color: #999999;
          margin-left: 0.5rem;
          word-break: break-all;
          min-width: 130px;
          text-align right;
        }
      }
      .servicesInner{
        width: 100%;
        display: flex;
        flex-flow: column;
        .titleBox {
          display: flex;
          flex-flow: row;
          justify-content: space-between;
          margin: 0.6rem 0;

          .title {
            font-size: 15px;
            color: #000000;
            font-weight: 600;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 1;
            overflow: hidden;
          }

          .time {
            font-size: 12px;
            color: #999999;
            margin-left: 0.5rem;
            word-break: break-all;
            min-width: 130px;
          }
        }

        .info {
          display: flex;
          flex-flow: row;
          flex-wrap: nowrap;
          position: relative;

          img, .thumb {
            width: 100px;
            min-width: 100px;
            height: 80px;
            object-fit: cover;
            margin-right: 0.5rem;
            border: 1px solid #eeeeee;
            background: #eeeeee;
            font-style: normal;
            border-radius: 3px;
            line-height: 80px;
            text-align: center;
            color: #ffffff;
            font-weight: 800;
            font-size: 1.15rem;
          }

          .detail {
            width: 100%;

            .row-label {
              // width: 100%;
              // display: flex;
              // flex-flow: row;

              // justify-content: flex-end;
              ul {
                &.tags {
                  // flex: 5;
                  max-width: 85%;
                  display: flex;
                  flex-flow: row;
                  align-items: center;
                  flex-wrap: wrap;
                  // height: 30px;
                  overflow: hidden;

                  li {
                    border: 1px solid #33cc66;
                    border-radius: 5rem;
                    background: #ffffff;
                    color: #666666;
                    font-size: 13px;
                    margin: 0.25rem;
                    // min-width: 60px;
                    padding: 0 0.5rem;
                  }
                }
              }

              .btns {
                // position: static;
                flex: 1;
              }
            }

            .desc {
              max-height: 48px;
              overflow: hidden;
              font-size: 14px !important;
              color: #666666;
              line-height: 24px;
              max-width: 778px;
              cursor: pointer;

              p {
                span {
                  font-size: 14px !important;
                }
              }

              p, span, div, ul, li, dl, dt, dd, strong, b {
                display: inline !important;
                background: none !important;
                font-size: 14px !important;
                color: #666666 !important;
                line-height: 24px !important;
                border: 0 !important;
                font-weight: normal !important;
                font-family: '微软雅黑', 'Arial Narrow', Arial, sans-serif !important;
              }
            }
          }
        }
      }
    }
  }
}
</style>
