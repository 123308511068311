<template>
  <div class="right-content">
    <div class="box-label">
      <div class="content-row">
        <div class="items-list user-group">
          <div class="item publish-user" v-if="data.showBtns && page === 1">
            <div
              class="thumb"
              @click="jumpPage({ name: 'publish', params: { type, active } })"
            >
              <i class="el-icon--plus"></i>
            </div>
          </div>
          <template v-if="data.items && data.items.length">
            <template v-for="(item, key) in data.items">
              <gigItem
                :item="item"
                :key="key"
                :small="true"
                :show-action="false"
                :edit="data.showBtns"
                :type="type === 'group' ? '4' : '5'"
                @delSuccess="handlerDelFunc"
              />
            </template>
          </template>
          <!-- <div v-else class="nodata"></div> -->
          <noData v-else-if="!data.showBtns" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import noData from "@/components/business/web-site/not-data";
import gigItem from "@/components/business/web-site/gig_item";
export default {
  name: "Item",
  components: {
    gigItem,
    noData
  },
  props: {
    data: {
      type: Object,
      default: () => {
        return {};
      }
    },
    type: {
      type: String,
      default: ""
    },
    active: {
      type: Number,
      default: 1
    },
    page: {
      type: Number,
      default: 1
    }
  },
  methods: {
    handlerDelFunc() {
      this.$emit("delSuccess");
    }
  }
};
</script>
<style lang="stylus" scoped>
.items-list {
  display: flex;
  flex-wrap: wrap;
  width: 100%;

  .item, .publish-user {
    background: #fff;
    margin: 0 0.35rem 0.5rem 0;
    width: 24.5%;
    padding: 0.5rem 0.4%;
    position: relative;
    border-radius: 5px;

    &:nth-child(4n) {
      margin-right: 0;
    }

    .thumb {
      flex-flow: row;
      align-items: center;
      justify-content: center;
      color: #33cc66;
      height: 10rem;
      border-radius: 3px;
      text-align: center;
      line-height: 10rem;
      border: 1px solid #e5e5e5;
      cursor: pointer;

      .el-icon--plus {
        font-size: 36px;
        font-weight: 800;
      }

      &:hover {
        opacity: 0.8;
      }
    }
  }
}
</style>
