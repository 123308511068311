<template>
  <div class="right-content">
    <!-- about me  -->
    <div class="box-label">
      <div class="content-row base">
        <div
          v-if="data.about_me || (data.baseInfo && data.baseInfo.tags.length)"
        >
          <ul class="tags">
            <li v-for="(tag, index) in data.baseInfo.tags" :key="index">
              {{ tag }}
            </li>
          </ul>
          <div v-html="data.about_me"></div>
        </div>
        <noData v-else />
      </div>
    </div>
    <!-- edu -->
    <div class="box-label">
      <div class="label">
        <i class="el-icon--edu"></i>
        <i
          v-if="data.showBtns"
          class="el-icon--plus"
          @click="
            jumpPage({ name: 'publish', params: { type: 'education', active } })
          "
        ></i>
      </div>
      <div class="content-row base">
        <ul class="edu" v-if="data.education && data.education.length">
          <li v-for="(item, index) in data.education" :key="index">
            <p class="label-row">
              <span
                class="title"
                v-html="
                  item.field_of_study
                    ? `${item.school}(${item.field_of_study})`
                    : item.school
                "
              ></span>
              <span class="btns" v-if="data.showBtns">
                <i
                  class="el-icon-delete"
                  @click="delSchool(item.education_id)"
                ></i>
                <i
                  class="el-icon-edit"
                  @click="
                    jumpPage({
                      name: 'publish',
                      params: {
                        type: 'education',
                        id: item.education_id,
                        active
                      }
                    })
                  "
                ></i>
              </span>
            </p>
            <span
              v-if="item.join_time && item.leave_time"
              class="time"
              v-html="item.join_time + '-' + item.leave_time"
            ></span>
            <span class="time" v-else-if="item.time_str">{{
              item.time_str
            }}</span>
            <span class="desc" v-html="item.description"></span>
          </li>
        </ul>
        <noData v-else />
      </div>
    </div>
    <!-- work exp -->
    <div class="box-label">
      <div class="label">
        <i class="el-icon--dir"></i>
        <i
          v-if="data.showBtns"
          class="el-icon--plus"
          @click="
            jumpPage({
              name: 'publish',
              params: { type: 'experience', active }
            })
          "
        ></i>
      </div>
      <div class="content-row base">
        <ul class="exper" v-if="data.experiance && data.experiance.length">
          <li v-for="(item, index) in data.experiance" :key="index">
            <p class="label-row">
              <span
                class="title"
                v-html="
                  item.title
                    ? item.company_name + ' ( ' + item.title + ' )'
                    : item.company_name
                "
              ></span>
              <span class="btns" v-if="data.showBtns">
                <i
                  class="el-icon-delete"
                  @click="delWrokExp(item.experiance_id)"
                ></i>
                <i
                  class="el-icon-edit"
                  @click="
                    jumpPage({
                      name: 'publish',
                      params: {
                        type: 'experience',
                        id: item.experiance_id,
                        active
                      }
                    })
                  "
                ></i>
              </span>
            </p>
            <span
              class="time"
              v-if="item.join_time && item.leave_time"
              v-html="
                item.join_time
                  ? item.join_time
                  : '' + '-' + item.leave_time
                  ? item.leave_time
                  : ''
              "
            ></span>
            <span class="time" v-else-if="item.time_str">{{
              item.time_str
            }}</span>
            <span class="desc" v-html="item.description"></span>
          </li>
        </ul>
        <noData v-else />
      </div>
    </div>
    <Comments v-if="data.id" :id="data.id" :type="3" :fouce="fources" @commentSuccessful="commentSuccessful"/>
    <tips v-if="showTips" :show-tips="showTips"></tips>
  </div>
</template>
<script>
import noData from "@/components/business/web-site/not-data";
import Comments from "@/components/business/web-site/comments";
import tips from "@/components/business/web-site/dialog/tip";
export default {
  name: "BaseInfo",
  props: {
    data: {
      type: Object,
      default: () => {
        return {};
      }
    },
    active: {
      type: Number,
      default: 3
    },
    fources: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      params: {
        language: localStorage.getItem("langCode") || "1",
        userId: this.$store.getters.userInfo.id
      },
      showTips: false
    };
  },
  components: {
    noData,
    Comments,
    tips
  },
  methods: {
    // 评论成功后回调，刷新页面
    commentSuccessful() {
      location.reload();
    },
    confrim() {},
    changeTipHandler() {
      this.showTips = !this.showTips;
      setTimeout(() => {
        this.showTips = !this.showTips;
      }, 2000);
    },
    async delSchool(id, icon) {
      this.params.educationId = id;
      if (!this.$store.getters.userInfo.id) {
        this.$store.commit("setLoginAndRegisterDialog", true);
        return;
      }
      let options = {
        confirmButtonText: " ",
        cancelButtonText: " ",
        type: "warning",
        center: true,
        customClass: "my-confirm",
        cancelButtonClass: "el-icon-close my-confirm-btn",
        confirmButtonClass: "el-icon-check my-confirm-btn"
      };
      options.iconClass = icon || "el-icon-delete";
      this.$confirm("", "", options || {})
        .then(() => {
          this.done(this.params, "rest/personalCenter/v3/deleteEducation");
        })
        .catch(e => {
          console.log(e);
        });
    },
    delWrokExp(id, icon) {
      this.params.experienceId = id;
      if (!this.$store.getters.userInfo.id) {
        this.$store.commit("setLoginAndRegisterDialog", true);
        return;
      }
      let options = {
        confirmButtonText: " ",
        cancelButtonText: " ",
        type: "warning",
        center: true,
        customClass: "my-confirm",
        cancelButtonClass: "el-icon-close my-confirm-btn",
        confirmButtonClass: "el-icon-check my-confirm-btn"
      };
      options.iconClass = icon || "el-icon-delete";
      this.$confirm("", "", options || {})
        .then(() => {
          this.done(this.params, "rest/personalCenter/v3/deleteExperience");
        })
        .catch(e => {
          console.log(e);
        });
    },
    async done(params, url) {
      let result = await this.$http({
        url,
        params,
        withCredentials: false
      }).catch(e => {
        console.log(e);
      });
      if (result) {
        this.$emit("change");
        this.changeTipHandler();
      } else {
      }
    }
  }
};
</script>
