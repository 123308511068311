var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "right-content" }, [
    _c("div", { staticClass: "box-label" }, [
      _c("div", { staticClass: "content-row" }, [
        _c(
          "div",
          { staticClass: "items-list user-group" },
          [
            _vm.data.showBtns && _vm.page === 1
              ? _c("div", { staticClass: "item publish-user" }, [
                  _c(
                    "div",
                    {
                      staticClass: "thumb",
                      on: {
                        click: function($event) {
                          return _vm.jumpPage({
                            name: "publish",
                            params: { type: _vm.type, active: _vm.active }
                          })
                        }
                      }
                    },
                    [_c("i", { staticClass: "el-icon--plus" })]
                  )
                ])
              : _vm._e(),
            _vm.data.items && _vm.data.items.length
              ? [
                  _vm._l(_vm.data.items, function(item, key) {
                    return [
                      _c("gigItem", {
                        key: key,
                        attrs: {
                          item: item,
                          small: true,
                          "show-action": false,
                          edit: _vm.data.showBtns,
                          type: _vm.type === "group" ? "4" : "5"
                        },
                        on: { delSuccess: _vm.handlerDelFunc }
                      })
                    ]
                  })
                ]
              : !_vm.data.showBtns
              ? _c("noData")
              : _vm._e()
          ],
          2
        )
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }