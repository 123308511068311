var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "right-content" }, [
    _vm.data.showBtns
      ? _c(
          "div",
          {
            staticClass: "box-label user-center-plus",
            on: {
              click: function($event) {
                return _vm.routeJump({ name: "newPublish" })
              }
            }
          },
          [_c("i", { staticClass: "el-icon--plus" })]
        )
      : _vm._e(),
    _c("div", { staticClass: "box-label" }, [
      _c(
        "div",
        { staticClass: "content-row contentserver" },
        [
          _vm.data.items && _vm.data.items.length
            ? _c(
                "ul",
                { staticClass: "services" },
                _vm._l(_vm.data.items, function(item, index) {
                  return _c("li", { key: index }, [
                    item.is_delete === "3"
                      ? _c(
                          "div",
                          { staticClass: "hasDeleteServes" },
                          [
                            _c("div", { staticClass: "leftContent" }, [
                              _vm._m(0, true),
                              _c("div", [
                                _vm._v(_vm._s(_vm.$t("violationTips")))
                              ])
                            ]),
                            _c("div", { staticClass: "rightContent" }, [
                              _vm._v(
                                "\n              " +
                                  _vm._s(item.publish_time) +
                                  "\n            "
                              )
                            ]),
                            _vm.data.showBtns
                              ? _c("edit", {
                                  attrs: {
                                    params: { type: 1, id: item.id },
                                    isDelete: true
                                  },
                                  on: { delSuccess: _vm.delSuccessHandler }
                                })
                              : _vm._e()
                          ],
                          1
                        )
                      : _vm._e(),
                    item.is_delete === "1"
                      ? _c("div", { staticClass: "servicesInner" }, [
                          _c("p", { staticClass: "titleBox" }, [
                            _c(
                              "span",
                              {
                                staticClass: "title",
                                on: {
                                  click: function($event) {
                                    return _vm.routeJump({
                                      name: "postContentDetails",
                                      params: { id: item.id }
                                    })
                                  }
                                }
                              },
                              [
                                _vm._v(
                                  _vm._s(item.i_can_do) + "\n              "
                                )
                              ]
                            ),
                            _c("span", { staticClass: "time" }, [
                              _vm._v(_vm._s(item.publish_time))
                            ])
                          ]),
                          _c("p", { staticClass: "info" }, [
                            item.cover
                              ? _c("img", {
                                  staticClass: "thumb",
                                  attrs: { src: item.cover },
                                  on: {
                                    click: function($event) {
                                      return _vm.routeJump({
                                        name: "postContentDetails",
                                        params: { id: item.id }
                                      })
                                    }
                                  }
                                })
                              : _c(
                                  "i",
                                  {
                                    staticClass: "thumb",
                                    on: {
                                      click: function($event) {
                                        return _vm.routeJump({
                                          name: "postContentDetails",
                                          params: { id: item.id }
                                        })
                                      }
                                    }
                                  },
                                  [_vm._v("Melinked\n              ")]
                                ),
                            _c("span", { staticClass: "detail" }, [
                              item.isNewDataStructure
                                ? _c(
                                    "div",
                                    {
                                      staticClass: "desc",
                                      on: {
                                        click: function($event) {
                                          return _vm.routeJump({
                                            name: "postContentDetails",
                                            params: { id: item.id }
                                          })
                                        }
                                      }
                                    },
                                    _vm._l(item.detail, function(
                                      content,
                                      index
                                    ) {
                                      return _c(
                                        "span",
                                        { key: content.key + index },
                                        [
                                          _vm._v(
                                            "\n                    " +
                                              _vm._s(
                                                content.key !== "web"
                                                  ? content.key === "url"
                                                    ? content.content.showLink
                                                    : content.content
                                                  : content.content.content
                                              ) +
                                              "\n                  "
                                          )
                                        ]
                                      )
                                    }),
                                    0
                                  )
                                : _vm._e(),
                              !item.isNewDataStructure
                                ? _c("div", {
                                    staticClass: "desc",
                                    domProps: {
                                      innerHTML: _vm._s(item.detail)
                                    },
                                    on: {
                                      click: function($event) {
                                        return _vm.routeJump({
                                          name: "postContentDetails",
                                          params: { id: item.id }
                                        })
                                      }
                                    }
                                  })
                                : _vm._e(),
                              _c(
                                "label",
                                { staticClass: "row-label" },
                                [
                                  _c(
                                    "ul",
                                    { staticClass: "tags" },
                                    [
                                      _vm._l(item.tags.split(","), function(
                                        tag,
                                        index1
                                      ) {
                                        return [
                                          tag
                                            ? _c("li", { key: index1 }, [
                                                _vm._v(
                                                  "\n                        " +
                                                    _vm._s(
                                                      tag.length > 50
                                                        ? tag.slice(0, 60) +
                                                            "..."
                                                        : tag
                                                    ) +
                                                    "\n                      "
                                                )
                                              ])
                                            : _vm._e()
                                        ]
                                      })
                                    ],
                                    2
                                  ),
                                  _vm.data.showBtns
                                    ? _c("edit", {
                                        attrs: {
                                          needEdit: true,
                                          isEditPost: true,
                                          params: { type: 1, id: item.id }
                                        },
                                        on: {
                                          delSuccess: _vm.delSuccessHandler
                                        }
                                      })
                                    : _vm._e()
                                ],
                                1
                              )
                            ])
                          ])
                        ])
                      : _vm._e()
                  ])
                }),
                0
              )
            : _c("noData")
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "deleteIconBox" }, [
      _c("div", { staticClass: "deleteIcon" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }